import React, { useEffect, useRef, useState } from "react";
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar]);



const mapNumber = (number, in_min, in_max, out_min, out_max) => {
    return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};


function CapabilitesCloud(props) {
    const [style, setStyle] = useState({});
    const [isActiveEven, setIsActiveEven] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsActiveEven(prevState => !prevState);
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX - left;
        const mouseY = e.clientY - top;

        // Calculate whether the cursor is on the left/right half and top/bottom half
        const isCursorLeftSide = mouseX < width / 5;
        const isCursorTopSide = mouseY < height / 5;

        // Calculate movement direction and magnitude
        // Invert direction based on cursor position. Adjust divisor for sensitivity.
        const moveX = isCursorLeftSide ? (width / 2 - mouseX) / 50 : -(mouseX - width / 2) / 50;
        const moveY = isCursorTopSide ? (height / 2 - mouseY) / 30 : -(mouseY - height / 2) / 30;

        setStyle({
            transform: `translate3d(${moveX}px, ${moveY}px, 0)`,
        });
    };
    const handleMouseLeave = () => {
        setStyle({});
    };


    return (
        <section className="service-section capabilities-consulting capabilities-solution capabilities-cloud" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
            <div className="cloud-left">
                <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">CoreSite<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">GCP<div className="boxmovement" style={style}></div></div>
                    <div className="verticle-box">AWS<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">Fastly<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">Cloudflare<div className="boxmovement" style={style}></div></div>
                    <div className="verticle-box">Azure<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">Oracle<div className="boxmovement" style={style}></div></div>
                </div>
            </div>
            <div className="mobile_cloud_btn">
                <div className="top_cloud">
                    <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                        <div className="verticle-box" >VMware</div>
                    </div>
                    <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                        <div className="verticle-box">Akamai</div>
                    </div>
                    <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                        <div className="verticle-box">IBM Cloud</div>
                    </div>
                </div>
                <div className="bottom_cloud">
                    <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                        <div className="verticle-box" >QTS</div>
                    </div>
                    <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                        <div className="verticle-box">Constellix</div>
                    </div>
                    <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                        <div className="verticle-box">CDN77</div>
                    </div>
                </div>
            </div>
            <div className=" container pt-5 capabilities-cloud-banner">
                <div className="row">
                    <div className="  col-lg-12 col-xl-12 text-white order-lg-1 mb-5 mb-lg-0 service-top">
                        <span className="badge bg-muted-purple text-white rounded-pill mb-2">
                            Managed Cloud & Cybersecurity Services
                        </span>
                        <h1>
                            <span className="text-green">Cloud & Security</span>, Simplified</h1>
                        <p>Our Managed Cloud and Cybersecurity Services safeguard your invaluable digital assets, ensuring uninterrupted access and availability while seamlessly scaling to the demands of your growing operations.</p>
                        <a href="/contact/" className='button-link'>Contact Us
                            <div className="arrow_movement">
                                <img src={BtnArrow} alt='' />
                                <img src={BtnArrowbottom} alt='' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="cloud-right" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box" >VMware<div className="boxmovement" style={style}></div>
                    </div>
                </div>
                <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box" >Akamai  <div className="boxmovement" style={style}></div></div>
                    <div className="verticle-box">QTS<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">IBM Cloud<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${!isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">Constellix<div className="boxmovement" style={style}></div></div>
                    <div className="verticle-box">CDN77<div className="boxmovement" style={style}></div></div>
                </div>
                <div className={`cap-box ${isActiveEven ? 'active' : ''}`}>
                    <div className="verticle-box">Red Hat<div className="boxmovement" style={style}></div></div>
                </div>
            </div>


        </section>
    )
}


export default CapabilitesCloud;