import React from 'react';
import { Helmet } from "react-helmet";

function ImageTitleDescriptionBullets({ featureImage, title, description, bullet1, bullet2, bullet3, subTitle, flip, id }) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section className={flip ? "inverted mx-xl-3 solution-cloud" : "inverted mx-xl-3 feature-video"} id={id}>
                <div className='container'>
                    <div className='row align-items-center'>
                        {flip ? (
                            <>
                                <div className='col-md-6'>
                                    <div className='feature-right'>
                                        {subTitle && <span className='sub-title'>{subTitle}</span>}
                                        <h2>{title}</h2>
                                        <p>{description}</p>
                                        <ul className='list-icon'>
                                            <li>{bullet1}</li>
                                            <li>{bullet2}</li>
                                            <li>{bullet3}</li>
                                        </ul>   
                                        {/* <a href='#' className='button-link'>Explore Managed Cloud & Cybersecurity Services
                                            <div className="arrow_movement">
                                                <img src={BtnArrow} alt=''/>
                                                <img src={BtnArrowbottom} alt=''/>
                                            </div>
                                        </a> */}
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <img src={featureImage} alt='Feature' /> 
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='col-md-6'>
                                    <img src={featureImage} alt='Feature' /> 
                                </div>
                                <div className='col-md-6'>
                                    <div className='feature-right'>
                                        {subTitle && <span className='sub-title'>{subTitle}</span>}
                                        <h2>{title}</h2>
                                        <p>{description}</p>
                                        <ul className='list-icon'>
                                            <li>{bullet1}</li>
                                            <li>{bullet2}</li>
                                            <li>{bullet3}</li>
                                        </ul>   
                                        {/* <a href='#' className='button-link'>Explore Managed Cloud & Cybersecurity Services
                                            <div className="arrow_movement">
                                                <img src={BtnArrow} alt=''/>
                                                <img src={BtnArrowbottom} alt=''/>
                                            </div>
                                        </a> */}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ImageTitleDescriptionBullets;
